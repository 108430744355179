import React, { ReactElement } from "react";
import styled from "styled-components";
import { TITLE_FONTSIZE } from "../utilities/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";

interface ModalProps {
  onClose: Function;
  body: ReactElement;
  title?: ReactElement | string;
  footer?: ReactElement;
  noCloseButton?: boolean;
  description?: string;
  borderRadius?: string;
  maxWidth?: string;
}

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  return (
    <ModalWrapper>
      <ModalOverlay onClick={() => props.onClose()}>
        <ModalArea onClick={(event) => event.stopPropagation()} style={{borderRadius: props.borderRadius}} maxWidth={props.maxWidth}>
          <ModalButtonArea>
            <CloseButton hidden={props.noCloseButton} onClick={() => props.onClose()}>
              <FontAwesomeIcon icon={faClose} />
            </CloseButton>
          </ModalButtonArea>
          <ModalContent>
            {(props.title || props.description) && (
              <ModalHeader>
                <ModalTitle>
                  <Trans>{props.title}</Trans>
                </ModalTitle>
                <ModalDescription>
                  <Trans>{props.description}</Trans>
                </ModalDescription>
              </ModalHeader>
            )}
            <ModalBody>
              <Trans>{props.body}</Trans>
            </ModalBody>
          </ModalContent>
          {props.footer && (
            <ModalFooter>
              <Trans>{props.footer}</Trans>
            </ModalFooter>
          )}
        </ModalArea>
      </ModalOverlay>
    </ModalWrapper>
  );
};

const CLOSEBUTTON_SIZE = 30;
const VERTICAL_GAP = "10px";
const HORIZONTAL_GAP = "20px";
const ModalWrapper = styled.div``;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #b2b2b299 0% 0% no-repeat padding-box;
  transition: opacity 200ms;
  z-index: 1001;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
`;

const ModalArea = styled.div<{ maxWidth?: string }>`
  background: white;
  max-width: ${props => props.maxWidth ? props.maxWidth : "900px"};
  min-height: fit-content;
  width: 100%;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ModalButtonArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ModalHeader = styled.div`
  flex: 1;
  padding: ${VERTICAL_GAP} ${HORIZONTAL_GAP};
  position: relative;
`;

const ModalBody = styled.div`
  flex: 4;
  padding: ${VERTICAL_GAP} ${HORIZONTAL_GAP};
`;

const ModalFooter = styled.div`
  flex: 3;
`;

const ModalTitle = styled.h1`
  margin: 12px 48px;
  font-weight: 600;
  font-size: ${TITLE_FONTSIZE};
  text-align: center;
  font-family: Poppins;
`;

const ModalDescription = styled.p`
  text-align: center;
  line-height: 20px;
`;

const CloseButton = styled.button`
  width: ${CLOSEBUTTON_SIZE}px;
  height: ${CLOSEBUTTON_SIZE}px;
  background: transparent;
  outline: 0;
  border: 1px solid gray;
  border-radius: 90px;
  margin-right: ${HORIZONTAL_GAP};
  margin-top: ${VERTICAL_GAP};
  color: gray;
  font-size: ${CLOSEBUTTON_SIZE * 0.6}px;
  line-height: ${CLOSEBUTTON_SIZE * 0.7}px;
  cursor: pointer;
  &:hover {
    color: lightgray;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 4;
`;

export default Modal;
